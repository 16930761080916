import React, { useState, useEffect } from "react";
import { Modal, DatePicker, Card, Radio, Space } from "antd";
import dayjs from "dayjs"; // Import dayjs
import "../CreatorProfile/CreatorProfile.css"
const { RangePicker } = DatePicker;

const CustomDateRange = (props: any) => {
  const {
    activeTab,
    setActiveTab,
    setIsCustomDateModalOpen,
    isCustomDateModalOpen,
    setSelectedDates,
    setCustomStartDate,
    setCustomEndDate,
    selectedDates,
    customStartDate,
    customEndDate
  } = props;
  const [localActiveTab, setLocalActiveTab] = useState<any>();
  const [errorMsg, setErrorMsg] = useState("");
  // useEffect(() => {
  //   setLocalActiveTab(activeTab);
  // }, [activeTab]);
  const items = [
    { label: "All", key: 0 },
    { label: "Last 7 days", key: 7 },
    { label: "Last 30 days", key: 30 },
    { label: "Last 90 days", key: 90 },
    { label: "Custom Date range", key: 1 },
  ];

  const handleMenuClick = (key: number) => {
    setActiveTab(key);
    setLocalActiveTab(key);
    if (key === 1) {
      setIsCustomDateModalOpen(true); // Open the custom date range modal
    }
  };
  const handleCustomDateCancel = () => {
    setIsCustomDateModalOpen(false); // Close the custom date range modal
    // setActiveTab(0);
    // setLocalActiveTab(0);
  };
  const handleCustomDateChange = (dates: any | null) => {
    setSelectedDates(dates);
    setErrorMsg("")
  };
  const disabledDate = (current: dayjs.Dayjs) => {
    // Can not select days after today
    return current && current.isAfter(dayjs().endOf("day"));
  };
  const handleSelectCustomDateRange = () => {
    if (selectedDates && selectedDates.length > 0) {
      setCustomStartDate(selectedDates?.[0]);
      setCustomEndDate(selectedDates?.[1]);
      setIsCustomDateModalOpen(false);
    } else {
      setErrorMsg("Please select a date")
    }
  };

  const renderCoustomDate = (date: any, key: number) => {
    if (date && key === 1 && activeTab ===1) {
      return (`(${dayjs(date?.[0]).format("MMMM D, YYYY")} - ${dayjs(date?.[1]).format("MMMM D, YYYY")}))`)
    } else if (customStartDate && customEndDate && key === 1 && activeTab ===1) {
      return (`(${dayjs(customStartDate).format("MMMM D, YYYY")} - ${dayjs(customEndDate).format("MMMM D, YYYY")})`)
    } else {
      return ""
    }
  }
  return (
    <Card className="tranding-card">
      {/* <h5 className="editTitle">Select Date Range</h5>
      <div
        className="date-scroll-trending-products trending-products"
      >
        {items.map((item) => (
          <p
            key={item.key}
            className={`date-selector-btn ${
              item.key === localActiveTab || item.key === activeTab
                ? "active"
                : ""
            }`}
            onClick={() => handleMenuClick(item.key)}
          >
            {item.label}
          </p>
        ))}
      </div>

      <Modal
        title="Select Custom Date Range"
        open={isCustomDateModalOpen}
        onCancel={handleCustomDateCancel}
        onOk={handleSelectCustomDateRange}
        okText="Select"
        className="modal-center"
        // footer={null}
      >
        <RangePicker
          className="mb-2"
          defaultValue={selectedDates}
          onChange={handleCustomDateChange}
          disabledDate={disabledDate} // Disable future dates
        />
      </Modal> */}

      <Radio.Group onChange={(e) => handleMenuClick(e.target.value)} value={activeTab}>
        <Space direction="vertical">
          {items.map((item) => (
            <Radio value={item.key} key={item.key}> {`${item.label} ${renderCoustomDate(selectedDates, item.key)}`} </Radio>
          ))}
        </Space>
      </Radio.Group>
      <Modal
        title="Select Custom Date Range"
        open={isCustomDateModalOpen}
        onCancel={handleCustomDateCancel}
        onOk={handleSelectCustomDateRange}
        okText="Select"
        className="modal-center"
      // footer={null}
      >
        <RangePicker
          className="mb-2"
          value={selectedDates}
          onChange={handleCustomDateChange}
          disabledDate={disabledDate} // Disable future dates
        />
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      </Modal>

    </Card>
  );
};

export default CustomDateRange;
