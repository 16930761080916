import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import "../../../StayUpToDateControls.css";

const ReelsContentComponent = (props: any) => {
  const { username, user_id, isReelsContentComponentOpen } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "Latest Instagram Reels",
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      "reels"
    );
    if (res === "true") {
      props.refetch();
      props.setIsContentModalOpen(false);
      props.setIsReelsContentComponentOpen(false);
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsContentModalOpen(false);
    props.setIsReelsContentComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsReelsContentComponentOpen(false);
    props.setIsContentModalOpen(true);
  };

  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button
              onClick={handlePrevious}
              className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isReelsContentComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
      >
        <h5 className="title mb-20">
          Your latest Instagram Reels will be shown.{" "}
        </h5>
      </Modal>
    </>
  );
};

export default ReelsContentComponent;
