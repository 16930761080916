import React from "react";
import LinksWrapper from "./TemplateWrapper/LinksWrapper/LinksWrapper";
import ContentWrapper from "./TemplateWrapper/ContentWrapper";
import CustomContentWrapper from "./TemplateWrapper/CustomContentWrapper";
import CustomLinksWrapper from "./TemplateWrapper/CustomLinksWrapper";
import BannerWrapper from "./TemplateWrapper/BannerWrapper";
import HighlightWrapper from "./TemplateWrapper/HighlightWrapper";
import MyLinksWrapper from "./TemplateWrapper/MyLinksWrapper";
import dayjs from "dayjs";
import ProductBankWrapper from "./TemplateWrapper/ProductBankWrapper";
import QueryLinksWrapper from "./TemplateWrapper/QueryLinksWrapper";
import QueryContentWrapper from "./TemplateWrapper/QueryContentWrapper";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import TrendingProductsWrapper from "./TemplateWrapper/TrendingProductsWrapper";
import NewsletterWrapper from "./TemplateWrapper/NewsletterWrapper";
import FormWrapper from "./TemplateWrapper/FormWrapper";
import NewsletterPopupWrapper from "./TemplateWrapper/NewsletterPopupWrapper";

const SubComponents = (props: any) => {
  const {
    username,
    user_id,
    component,
    isEdit,
    selectedComponent,
    setIsEdit,
    isEditModalOpen,
    setIsEditModalOpen,
    refetch,
    setSelectedComponent,
    brand_color,
  } = props;
  const daysSinceMultipleDays = (targetDaysOfWeek: number[]) => {
    const currentDayOfWeek = dayjs().day();
    const daysSinceTargetDays = targetDaysOfWeek.map(
      (targetDayOfWeek) => (currentDayOfWeek - targetDayOfWeek + 7) % 7
    );

    // return Math.min(...daysSinceTargetDays);
    return daysSinceTargetDays;
  };
  const dayMappings: { [key: string]: number[] } = StaticVariables().dayMappings
  const renderComponent = () => {
    const {
      display_name: displayName,
      name,
      type,
      post_ids: postIds,
    } = component;
    if (dayMappings[displayName]) {
      const numberOfDays = daysSinceMultipleDays(dayMappings[displayName]);
      return (
        <LinksWrapper
          key={component.id}
          user_id={user_id}
          username={username}
          numberOfDays={numberOfDays}
          title={component.name}
          isLinks={true}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          refetch={refetch}
          component={component}
          setSelectedComponent={setSelectedComponent}
          selectedComponent={selectedComponent}
          brand_color={brand_color}
          cta={component?.cta_placeholder}
        />
      );
    }
    switch (component.display_name) {
      case "My Links":
        return (
          <MyLinksWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
        case "Inline Newsletter":
          return (
            <NewsletterWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={component.name}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              flowdeskId={component?.details?.split(',')[0]}
            />
          );
          case "Popup Newsletter":
          return (
            <NewsletterPopupWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={component.name}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              flowdeskId={component?.details?.split(',')[0]}
            />
          );
          case "Form":
          return (
            <FormWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={component.name}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              filloutId={component?.details}
            />
          );
  
      case "reels":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="instagram"
            type="REELS"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "tiktok":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="tiktok"
            type="TIKTOK"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "instagram story":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="instagram"
            type="STORY"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "podcast":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="podcast"
            type="PODCAST"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "youtube":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="youtube"
            type="VIDEO"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "This Week":
        return (
          <LinksWrapper
            user_id={user_id}
            username={username}
            numberOfDays={[7]}
            title={component.name}
            isLinks={true}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            refetch={refetch}
            component={component}
            setSelectedComponent={setSelectedComponent}
            selectedComponent={selectedComponent}
            brand_color={brand_color}
            cta={component?.cta_placeholder}

            // handleShop={handleShop}
          />
        );
      case "Yesterday":
        return (
          <LinksWrapper
            user_id={user_id}
            username={username}
            numberOfDays={[1]}
            title={component.name}
            isLinks={true}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            refetch={refetch}
            component={component}
            setSelectedComponent={setSelectedComponent}
            selectedComponent={selectedComponent}
            brand_color={brand_color}
            cta={component?.cta_placeholder}

            // handleShop={handleShop}
          />
        );
      default:
        if (component.type === "content") {
          return (
            <CustomContentWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={component.name}
              platform="instagram"
              type={component?.type}
              postIds={component.post_ids}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
            />
          );
        } else if (component.type === "query-content") {
          return (
            <QueryContentWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={component.name}
              platform="instagram"
              type={component?.type}
              postIds={component.post_ids}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              query={component?.query}
              removedPostIds={component?.removed_postids}
            />
          );
        } else if (component.type === "links") {
          return (
            <CustomLinksWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={8}
              title={component.name}
              isLinks={true}
              postIds={component.post_ids}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
            />
          );
        } else if (component.type === "query-links") {
          return (
            <QueryLinksWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={8}
              title={component.name}
              isLinks={true}
              postIds={component.post_ids}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              query={component?.query}
              removedPostIds={component?.removed_postids}
            />
          );
        } else if (component.type === "product-bank") {
          return (
            <ProductBankWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={8}
              title={component.name}
              isLinks={true}
              postIds={component.post_ids}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
            />
          );
        }else if (component.type === "trending products") {
          return (
            <TrendingProductsWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              details={component?.details}
              title={component.name}
              isLinks={true}
              postIds={component.post_ids}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              removedPostIds={component?.removed_postids}

            />
          );
        }
         else if (component.type === "banner") {
          return (
            <BannerWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              title={component.name}
              component={component}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
            />
          );
        } else if (component.type === "highlight") {
          return (
            <HighlightWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={component.name}
              postIds={component.post_ids}
              data={component}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
            />
          );
        }
        return null;
    }
  };

  return <React.Fragment>{renderComponent()}</React.Fragment>;
};

export default SubComponents;
