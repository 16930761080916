import { Alert, Input, Modal, Spin } from "antd";
import React, { useState } from "react";
import { storeFilloutId, storeFlowdeskId, storeYoutubeChannel } from "../../../Utils/SupabaseServices/ContentDatabase";
import { useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";

const FilloutIntegrationModal = (props: any) => {
  const { showFilloutModal, user_id, data, refetchData,setShowFilloutModal } = props;
  const [FilloutId, setFilloutId] = useState(data?.flowdesk_user);
  const { username } = useParams();
  const [errorFilloutId, setErrorFilloutId] = useState("");
  const [loadingFlowdeskConnect, setLoadingFlowdeskConnect] = useState(false);
  const [showFormIdInstructions, setShowFormIdInstructions] = useState(false);
  return (
    <>
      {" "}
      <Modal
        title="Connect Fillout"
        open={showFilloutModal}
        onOk={async () => {
          setShowFilloutModal(true);

          await storeFilloutId(
            user_id as string,
            FilloutId,
            username
          );
          // if (error) {
          //   setErrorFilloutId(message);
          // } else {
            refetchData();
            setShowFilloutModal(false);
          // }
          setLoadingFlowdeskConnect(false);
          setFilloutId("")
        }}
        onCancel={() => {
          setErrorFilloutId("");
          setShowFilloutModal(false);
          setFilloutId("")
        }}
        okText="Connect"
        okButtonProps={{ loading: loadingFlowdeskConnect }}
        cancelButtonProps={{ disabled: loadingFlowdeskConnect }}
      >
        <p>
          By connecting your fillout, form of your choice is displayed in the
          Stay up to date component of your main page.{" "}
        </p>
        {loadingFlowdeskConnect ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {errorFilloutId && (
              <Alert
                type="error"
                message={`Error: ${errorFilloutId}`}
                showIcon
                className="alert-margin"
              />
            )}

            <p>To connect just enter your Form Link:</p>
            <Input
              placeholder="Form Link"
              value={FilloutId}
              onChange={(e) => {
                setFilloutId(e.target.value);
              }}
            />
            {!showFormIdInstructions ? (
              <p
                className="account-social-content-channelid-text"
                onClick={() => setShowFormIdInstructions(true)}
              >
                Find Your Form Link
              </p>
            ) : (
              <div className="account-social-content-Formid-ins">
                <p>
                  You can see your Form Link in your{" "}
                  <a
                    href="https://www.fillout.com/"
                    className="account-social-content-channelid-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    account settings
                  </a>
                </p>
                <ol>
                  <li>Sign in to Flodesk</li>
                  <li>Choose and design your form</li>
                  <li>
                    After you've customized it, go to share
                  </li>
                  <li>
                    Publish it, make sure to keep in <b>standard</b> type
                  </li>
                  <li>
                    Copy and paste your <b>Form link</b> here
                  </li>
                </ol>
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default FilloutIntegrationModal;
