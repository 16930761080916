import React, { Fragment, useState } from "react";
import { Button, Carousel, Modal } from "antd";

import { MdOutlineDragIndicator, MdOutlineFileDownload } from "react-icons/md";
import TagsBox from "../../../components/TagsBox/TagsBox";
import "../Feed/feedTab.css";
import { updateCommentsData } from "../../../Utils/SupabaseServices/CommentsDatabase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useUpdatePostAdIndex from "../Feed/useUpdatePostIndex";
import useDeletePosts from "../Feed/useDeletePosts";
import ProductBankActionButtons from "./ProductBankActionButtons";
import useUpdateProductTags from "./useUpdateProductTags";
import { StaticCompnents } from "../../../Utils/StaticComponent";


const ProductBankSearchTable = ({
  username,
  activeKey,
  data,
  user_id,
  filter,
  setLimitError,
  refetchData,
  setIsContentLoading,
  subscription_type,
  brokenLinksContent,
  currentPage,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [checkedList, setCheckedList] = useState<any>([]);
  const [selectedPostId, setSelectedPostId] = useState<any>();

  const handleCancel = () => {
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const { mutate: updateTags } = useUpdateProductTags();
  const { mutate: updatePostAdIndex } = useUpdatePostAdIndex();

  const { mutate: deletePostPermanently } = useDeletePosts();
 
  function removeTag(
    tagArr: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    const idx = tagArr.indexOf(tag);
    tagArr.splice(idx, 1);

    updateTags({
      currentPage,
      activeKey: "Product Bank",
      tagString: tagArr.toString(),
      post_id,
      pageIndex,
      index,
      filter,
      username,
      tag,
      action: "remove",
      user_id,
    });
  }
  function submitTag(
    e: any,
    tagArray: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();
    if (e.keyCode === 13) {
      let tagsToProcess = tag.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      const resultString = modifiedArray.toString();
      let arrString = tagArray
        ? [`${resultString}`, ...tagArray.split(",")].toString()
        : `${resultString}`;

      updateTags({
        currentPage,
        activeKey: "Product Bank",
        tagString: arrString,
        post_id,
        pageIndex,
        index,
        filter,
        username,
        tag,
        action: "submit",
        user_id,
      });
      // });

      e.target.value = "";
    }
  }

  function deletePost(post_id: string, pageIndex: number, index: number) {
    deletePostPermanently({
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex: index,
      filter,user_id
    });
  }

  const handleOk = async () => {
    if (checkedList && checkedList.length) {
      checkedList.map(async (list: any) => {
        await updateCommentsData(list);
      });
    }
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const newData = [...data?.pages?.[0]];
    let sourceIndex = result.source.index;
    let post_id = result.draggableId;
    let targetIndex = result.destination.index;

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    data.pages[0] = [...newData];

    updatePostAdIndex({
      user_id,
      sourceIndex,
      targetIndex,
      activeKey,
      post_id,
      username,
      newData,
    });
  };
  const platformIcons: any = {
    ShopMy: StaticCompnents().ShopMyIcon,
    Amazon: StaticCompnents().AmazonIcon,
    LTK: StaticCompnents().LTKIcon,
    instagram: StaticCompnents().InstagramIcon,
    pinterest:StaticCompnents().PinterestIcon,
    twitter:StaticCompnents().TwitterIcon,
    tiktok:StaticCompnents().TiktokIcon,
    newsletter:StaticCompnents().NewsletterIcon,
    podcast:StaticCompnents().PodcastIcon,
    // Add other platforms here
  };
  const renderPlatformIcon = (platform: any) => {
    const IconComponent = platformIcons[platform];

    if (IconComponent) {
      return <IconComponent />;
    }
    return null; // Return null if platform doesn't exist or is invalid
  };
  return (
    <>
      <DragDropContext
        onDragEnd={(result: any) => {
          onDragEnd(result);
        }}
      >
        <Droppable droppableId="ad">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="feed-tab-posts-table">
                {data && data.length > 0 ? (
                  <Fragment key={1}>
                    {data.map((image: any, index: number) => (
                      <Draggable
                        key={image.table_id}
                        draggableId={image.table_id}
                        index={index}
                        isDragDisabled={
                          activeKey === "Product Bank" ? false : true
                        }
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`feed-tab-post-row ${
                              activeKey === "active,ad"
                                ? "feed-tab-post-row-grab"
                                : ""
                            } ${
                              draggedIndex === index
                                ? "feed-tab-post-row-dragging"
                                : ""
                            }`}
                            // style={activeKey === "active,ad"?{cursor:'pointer'}:{cursor:'grab'}}
                          >
                            {activeKey === "active,ad" && (
                              <div className="feed-tab-drag-btn">
                                <MdOutlineDragIndicator />
                              </div>
                            )}

                            <div className="feed-tab-row-media-bank">
                              <div
                                className="feed-tab-row-media-wrapper"
                                style={
                                  image.platform === "newsletter"
                                    ? {
                                        backgroundColor: "#efeded",
                                        textAlign: "center",
                                      }
                                    : {}
                                }
                              >
                                {image.platform !== "newsletter" ? (
                                  <>
                                    {image.table_image ? (
                                      <>
                                        {image.table_image.split(",").length >
                                          1 &&
                                        image.table_image
                                          .split(",")
                                          .every(
                                            (url: any) => url.trim() !== ""
                                          ) ? (
                                          <Carousel
                                            dotPosition="bottom"
                                            className="feed-tab-media-carousel"
                                          >
                                            {image.table_image
                                              .split(",")
                                              .map(
                                                (
                                                  media_url: any,
                                                  index: any
                                                ) => (
                                                  <React.Fragment key={index}>
                                                    {media_url.includes(
                                                      "video"
                                                    ) ? (
                                                      <video
                                                        className="feed-tab-media mh-350"
                                                        autoPlay
                                                        playsInline
                                                        muted
                                                        loop
                                                        poster={
                                                          image.table_image &&
                                                          image.table_image.split(
                                                            ","
                                                          ).length &&
                                                          image.table_image.split(
                                                            ","
                                                          )[index]
                                                            ? image.table_image.split(
                                                                ","
                                                              )[index]
                                                            : "/assets/dummy_video.png"
                                                        }
                                                      >
                                                        <source
                                                          src={media_url}
                                                        />
                                                      </video>
                                                    ) : (
                                                      <img
                                                        className="feed-tab-media mh-300" 
                                                        src={media_url}
                                                        alt="post"
                                                      />
                                                    )}
                                                  </React.Fragment>
                                                )
                                              )}
                                          </Carousel>
                                        ) : (
                                          <img
                                            className="feed-tab-media post"
                                            src={
                                              image.table_image
                                                ? image.table_image
                                                : "/assets/dummy_video.png"
                                            }
                                            alt="post"
                                          />
                                        )}
                                        
                                          <p className="image-table-name">
                                            {image.table_name}
                                          </p>
                                        
                                      </>
                                    ) : (
                                      <img
                                        className="feed-tab-media"
                                        src={
                                          image.table_image
                                            ? image.table_image
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                ) : (
                                  <div>
                                    <h4 className="mt-7">
                                      {new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        day: "numeric",
                                        month: "long",
                                      }).format(new Date(image.post_date))}
                                    </h4>
                                    <h4> Newsletter</h4>
                                  </div>
                                )}
                                  <p className="creator-feed-content">
        {new Date(image.post_date).getFullYear() > 2000 && (
          <span className="creator-feed-content-date">
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              day: "numeric",
              month: "long",
            }).format(new Date(image.post_date))}
          </span>
        )}
        {image.platform && (
          <span className="creator-feed-content-icon">
            {renderPlatformIcon(image.platform)}
          </span>
        )}
      </p>
                              </div>

                              <div className="feed-tab-row-actions-mobile">
                                <ProductBankActionButtons
                                  post={image}
                                  postIndex={image.index}
                                  postId={image.table_id}
                                  pageIndex={image.pageIndex}
                                  index={index}
                                  type={"PRODUCT"}
                                  url={image.table_image}
                                  link={image.table_url}
                                  data={image}
                                  user_id={user_id}
                                  refetchData={refetchData}
                                  platform ={image.platform}
                                />
                              </div>
                            </div>

                            <div className="feed-tab-row-tags">
                              <div className="search-container-tagInput">
                                <input
                                  type="text"
                                  id="tagInput"
                                  enterKeyHint="enter"
                                  placeholder="Enter tags here"
                                  className="tagInput"
                                  onKeyUp={(e: any) => {
                                    submitTag(
                                      e,
                                      image.table_tags,
                                      e.target.value,
                                      image.table_id,
                                      1,
                                      1
                                    );
                                  }}
                                />
                              </div>
                              <div
                                className="image-table-tags"
                              >
                                {image.table_tags ? (
                                  <TagsBox
                                    tagsArray={
                                      image.table_tags
                                        ? image.table_tags.split(",")
                                        : [""]
                                    }
                                    removeTag={(tag: string) => {
                                      removeTag(
                                        image.table_tags
                                          ? image.table_tags.split(",")
                                          : [""],
                                        tag,
                                        image.table_id,
                                        1,
                                        index
                                      );
                                    }}
                                    page="content"
                                  />
                                ) : (
                                  <p className="noTags">No Tags</p>
                                )}
                              </div>
                            </div>

                            <div className="feed-tab-actions">
                              <ProductBankActionButtons
                                post={image}
                                postIndex={image.index}
                                postId={image.table_id}
                                pageIndex={image.pageIndex}
                                index={index}
                                platform={image.platform}
                                type={"PRODUCT"}
                                url={image.table_image}
                                link={image.table_url}
                                data={image}
                                user_id={user_id}
                                refetchData={refetchData}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                      //       {provided.placeholder}
                      //     </div>
                      //   )}
                      // </Droppable>
                    ))}
                  </Fragment>
                ) : (
                  <p className="">
                    {filter && filter !== "" ? (
                      <>
                        {activeKey === "archived" ? (
                          <i>
                            No archived posts found matching your search for{" "}
                            <b>{filter}</b>.
                          </i>
                        ) : activeKey === "active,ad" ? (
                          <i>
                            No ads found matching your search for{" "}
                            <b>{filter}</b>.
                          </i>
                        ) : activeKey === "active,featured" ? (
                          <i>
                            No featured posts found matching your search for{" "}
                            <b>{filter}</b>.
                          </i>
                        ) : (
                          <i>No search results found.</i>
                        )}
                      </>
                    ) : (
                      <>
                        {activeKey === "archived" ? (
                          <i>No posts have been archived yet.</i>
                        ) : activeKey === "active,ad" ? (
                          <i>No posts have been marked as an ad yet.</i>
                        ) : activeKey === "active,featured" ? (
                          <i>No posts have been marked as featured yet.</i>
                        ) : (
                          <i>
                            No posts!
                          </i>
                        )}
                      </>
                    )}
                  </p>
                )}
                {/* ))} */}
              </div>
            
              {/* Your Modal component */}
              <Modal
                title="Show Relevant Comments"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                bodyStyle={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 300px)",
                }}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    // loading={loading}
                    onClick={handleOk}
                  >
                    Save
                  </Button>,
                ]}
              >
                {/* Your Modal content */}
              </Modal>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ProductBankSearchTable;
