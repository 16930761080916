import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import TiktokContentComponent from "./Subcomponents/TiktokContentComponent";
import InstagramStoryContentComponent from "./Subcomponents/InstagramStoryContentComponent";
import YoutubeContentComponent from "./Subcomponents/YoutubeContentComponent";
import PodcastContentComponent from "./Subcomponents/PodcastContentComponent";
import ReelsContentComponent from "./Subcomponents/ReelsContentComponent";
import CustomContentComponents from "./Subcomponents/CustomContentComponents";
import { GrNext } from "react-icons/gr";
import "../../StayUpToDateControls.css"
import QueryContentComponents from "./Subcomponents/QueryContentComponents";


const ContentComponent = (props: any) => {
  const { isContentModalOpen,user_id,username,StayUpToDateComponents } = props;
  const [isTiktokContentComponentOpen,setIsTiktokContentComponentOpen]=useState(false)
  const [isInstagramStoryContentComponentOpen,setIsInstagramStoryContentComponentOpen]=useState(false)
  const [isYoutubeContentComponentOpen,setIsYoutubeContentComponentOpen]=useState(false)
  const [isPodcastContentComponentOpen,setIsPodcastContentComponentOpen]=useState(false)
  const [isReelsContentComponentOpen,setIsReelsContentComponentOpen]=useState(false)
  const [isCustomContentComponentOpen,setIsCustomContentComponentOpen]=useState(false)
  const [isQueryContentComponentOpen,setIsQueryContentComponentOpen]=useState(false)

  function handleTiktok(){
    setIsTiktokContentComponentOpen(true)
    props.setIsContentModalOpen(false)
  }
  function handleInstagramStory(){
    setIsInstagramStoryContentComponentOpen(true)
    props.setIsContentModalOpen(false)
  }
  function handleYoutube(){
    setIsYoutubeContentComponentOpen(true)
    props.setIsContentModalOpen(false)
  }
  function handlePodcast(){
    setIsPodcastContentComponentOpen(true)
    props.setIsContentModalOpen(false)
  }
  function handleReels(){
    setIsReelsContentComponentOpen(true)
    props.setIsContentModalOpen(false)
  }
  function handleCustomContent(){
    setIsCustomContentComponentOpen(true)
    props.setIsContentModalOpen(false)
  }
  function handleQueryContent(){
    setIsQueryContentComponentOpen(true)
    props.setIsContentModalOpen(false)
  }
  const data = [
    {
      // icon:,
      title: "Latest Instagram Stories",
      description: "Automated | Last 25 Stories", 
      component:handleInstagramStory,
      disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'instagram story')



      //   task: true,
    },
    {
      // icon:,
      title: "TikTok Posts",
      description: "Automated | Last 25 TikToks",
      component:handleTiktok,
      disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'tiktok')

      //   task: true,
    },
    {
      // icon:,
      title: "Latest Instagram Reels",
      description: "Automated | Last 25 Reels",
      component:handleReels,
      disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'reels')


    },
    // {
    //   // icon:,
    //   title: "Youtube",
    //   component:handleYoutube,
    //   disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'youtube')



    // },
    {
      // icon:,
      title: "Podcast",
      description: "Automated | Last 25 Episodees",
      component:handlePodcast,
      disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'podcast')


    },
    {
      // icon:,
      title: "Show Content Based on a Specific Word or Phrase",
      description: "Automated",
      task: true,
      component: handleQueryContent,
    },
    {
      // icon:,
      title: "Custom Content Carousel",
      description: "Manual | Create a carousel using any of your content",
      task: true,
      component: handleCustomContent,
    },
  ];
  const handleOk = () => {
    props.setIsContentModalOpen(false);
  };

  const handleCancel = () => {
    props.setIsContentModalOpen(false);
  };
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsContentModalOpen(false);
  };
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button onClick={handlePrevious} className="newbutton marginR-auto">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isContentModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <h3 className="title">Content Carousel Options</h3>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
               <Button
                className="list-item-btn h-auto"
                type="text"
                onClick={item.component}
                disabled={item.disabled}
              >
                <div
                className="list-btn-text flex-direction"
                >
                  <p>{item.title}</p>
                  <p className="item-description">{item.description}</p>
                </div>
              
              </Button>
            </List.Item>
          )}
        />
      </Modal>
      <TiktokContentComponent
        isTiktokContentComponentOpen={isTiktokContentComponentOpen}
        setIsTiktokContentComponentOpen={setIsTiktokContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
      <InstagramStoryContentComponent
        isInstagramStoryContentComponentOpen={isInstagramStoryContentComponentOpen}
        setIsInstagramStoryContentComponentOpen={setIsInstagramStoryContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
       <YoutubeContentComponent
        isYoutubeContentComponentOpen={isYoutubeContentComponentOpen}
        setIsYoutubeContentComponentOpen={setIsYoutubeContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
       <PodcastContentComponent
        isPodcastContentComponentOpen={isPodcastContentComponentOpen}
        setIsPodcastContentComponentOpen={setIsPodcastContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
       <ReelsContentComponent
        isReelsContentComponentOpen={isReelsContentComponentOpen}
        setIsReelsContentComponentOpen={setIsReelsContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
       <CustomContentComponents
        isCustomContentComponentOpen={isCustomContentComponentOpen}
        setIsCustomContentComponentOpen={setIsCustomContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
       <QueryContentComponents
        isQueryContentComponentOpen={isQueryContentComponentOpen}
        setIsQueryContentComponentOpen={setIsQueryContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
    </>
  );
};

export default ContentComponent;
