import Feed from "../Feed/Feed";
import CreatorProfile from "../../CreatorProfile/creatorProfile";
import { useState, useEffect } from "react";
import FeedPosts from "../../skeleton/Views/skeletonComponents/feedPosts";
import Skeleton from "react-loading-skeleton";
import "../../../styles/skeleton.css";
import { useLocation } from "react-router-dom";
const FeedControls = ({
  indexPages,
  userDetails,
  username,
  page,
  name,
  userId,
  data,
  hashtagFilters,
  avgWords,
  liveView,
}: {
  indexPages?: any;
  userDetails?: any;
  username?: string;
  page: string;
  name?: string;
  userId?: string;
  data?: any;
  hashtagFilters?: any;
  avgWords: any;
  liveView: any;
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchLoading, setShowSearchLoading] = useState(false);
  const location = useLocation();
  const [hashtagFilterArray, setHashtagFilterArray] =
    useState<any[]>(hashtagFilters);


  useEffect(() => {
    const updateSearchBar = () => {
      if (data?.feedControls?.checkedType === "Stay Up To Date") {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
       
        if (urlSearchParams?.size > 0 && (params.hasOwnProperty('term') || params.hasOwnProperty('from'))) {
          setShowSearch(true);
        } else {
        }
      } else {
        setShowSearch(true);
      }
    };

    updateSearchBar(); 
  }, [data?.feedControls?.checkedType, location.search]); 
  return (
    <>
      <div>

        <CreatorProfile
          userDetails={userDetails}
          showIcons={data?.feedControls?.showIcons}
          liveView={liveView}
          username={username}
          showSocialLinks={data?.feedControls.showSocialLinks}
          socialLinks={data?.userData}
          description={data?.description}
          setShowSearch={setShowSearch}
          showSearch={showSearch}
          setShowSearchLoading={setShowSearchLoading}
          desiredOrder={userDetails?.social_links_order?.split(",")}
        />
        {showSearchLoading ? (
          <>
            <div
              className="search-container-skeleton feed-search-skeleton"
            >
              <Skeleton
                className="search-wrapper-input feed-search-wrapper"
              />
            </div>
            <FeedPosts />
          </>
        ) : (
          <Feed
            indexPages={indexPages}
            isNecSearch={data?.userData?.isNecSearch}
            username={username}
            page={page}
            name={name}
            controls={data?.feedControls}
            activeFilters={data?.activeFilters}
            highlights={data?.highlights}
            customCategories={data?.customCategories}
            userId={userId}
            hashtagFilterArray={hashtagFilterArray}
            avgWords={avgWords}
            showSocials={data?.userData?.showSocials}
            is_nec_present={data?.userData.is_nec_present}
            contentType={data?.feedControls?.checkedType}
            contentPresent={data?.userExists}
            showSocialLinks={data?.feedControls.showSocialLinks}
            search_bar_text={
              data?.search_bar_text
                ? data?.search_bar_text
                : `Search ${name} Content`
            }
            contentOrder={data?.feedControls.contentOrder}
            isSearchPage={showSearch}
            brand_color={userDetails?.brand_color}
            setShowSearch={setShowSearch}
            isFilloutOn={data?.userData?.is_fillout_on}
          />
        )}
      </div>
    </>
  );
};

export default FeedControls;
