import React, { useState } from "react";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Button, Modal, Tabs } from "antd";
import { CiLock } from "react-icons/ci";
import { FaLock } from "react-icons/fa";

const TabOptions = (props: any) => {
  const { activeKey, subscription_type, brokenLinksContent, loadingRef ,setActiveFilter} =
    props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleOk = () => {
    return (
      // <div>
      <a
        href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
        target="_blank"
      >
        <button className="upgrade-subscription">Upgrade</button>
      </a>
      // {/* </div> */}
    );
    // setIsModalOpen(false);
  };
  const onChangeTab = (key: string) => {
    if (key === "active,ad" && subscription_type === "basic") {
      setIsModalOpen(true);
      loadingRef.current = true;
      props.setActiveKey(activeKey);

    } else {
      loadingRef.current = true;

      props.setActiveKey(key);
    }
    setActiveFilter(null)
    const elem: any = document.getElementById("search-feed");
    elem.value=''
    // if(key==="broken_links")
    // props.handleActiveFilter()
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const navTabs = [
    {
      key: "active",
      label: "Active",
      disabled: false,
    },
    {
      key: "active,ad",
      label: "Ads",
      disabled: subscription_type === "basic" ? true : false,
      isLocked: subscription_type === "basic" ? true : false,
    },
    {
      key: "active,featured",
      label: "Featured",
      disabled: false,
    },
    {
      key: "archived",
      label: "Archive",
      disabled: false,
    },
    {
      key: "broken_links",
      label: "Broken Links",
      disabled: false,
    },
  ];
  


  return (
    <>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChangeTab}>
        {navTabs.map((tab) => (
          <Tabs.TabPane
            key={tab.key}
            tab={
              <div className="tab-pane">
                {tab.isLocked && (
                  <FaLock className="fa-lock" />
                )}
                <span style={{ color: tab.isLocked ? "grey" : "" }}>
                  {tab.label}
                </span>
              </div>
            }
          ></Tabs.TabPane>
        ))}
      </Tabs>
      <Modal
        title="Upgrade to Unlock Ads"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="modal-text">
          Unlocking the Ads feature will allow you run branded or promotional
          content you've created as an ad in all of your search results.
        </p>
        <div className="text-right">
          <a
            href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
            target="_blank"
          >
            <button className="upgrade-subscription">Upgrade</button>
          </a>
        </div>
      </Modal>
    </>
  );
};

export default TabOptions;
